.product {
  padding: 40px 20px;
}

.product h1 {
  font-size: 1.6em;
  color: #6a6a6a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

.product h2 {
  font-size: 1.5em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

.product h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.product-box {
  /* border: 1px solid #c4c4c4; */
  border: none;
  width: 100%;
  /* cursor: pointer; */
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.product-container {
  display: flex;
  /* justify-content: space-between; */ /* cuando hay soat y avanzo express */
  justify-content: center; /*  cuando solo hay 1 tarjeta */
  margin-top: 40px;
}

.contentProduct {
  width: 45%;
  display: grid;
}

.button-req {
  background-color: #f2e879;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 1px solid black;
}

.button-req-detail {
  background-color: #0d5d8d;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 1px solid black;
  color: white;
}

.text {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: block;
}

.product-box:hover {
  background: #f2f7fc;
}
.product-box:hover h2,
.product-box.active h2 {
  color: black;
}

.product-box.active {
  color: white;
  background: #179ad7;
}

.product-box h3 {
  font-weight: 600;
  font-size: 18px;
  color: #4a4a4a;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.product-box h4 {
  color: #4a4a4a;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}

.product-box img {
  height: 120px;
  transition: all 0.3s ease-in-out;
}

.product-box:hover h4,
.product-box.active h4 {
  color: white;
  background-color: white;
}

.product-box:hover h3 {
  color: black;
}
.product-box.active h3 {
  color: white;
}

.product-box:hover img {
  filter: contrast(200%);
}
.product-box.active img {
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .product-box {
    width: 48%;
    margin-bottom: 20px;
  }

  .product-box img {
    width: 150px;
  }
}

@media (max-width: 450px) {
  .product-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .product-box img {
    width: 115%;
    height: 60px;
  }

  .product h2 {
    font-size: 1em;
  }
}
