.soat {
    width: 60%;
    margin: auto;
}

.formSoat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formSoat label {
        margin-top: 10px;
        margin-bottom: 10px

}