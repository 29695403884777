.header {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #00609c;
  height: 100px;
}

.header img {
  height: 80px;
  width: auto;
}

/* .header button {
  margin-left: auto;
} */
