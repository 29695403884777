@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap);
html, body {
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn-primary{
  background: #0086FF;
}

.fore-blue{
  color: #0086FF;
}

.fore-gray{
  color: #626262;
}

.text-center{
  text-align: center;
}
.global-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 30px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.input-width,
.input-width-fee,
.input-width-fee-active {
  width: 350px;
  max-width: 100%;
}

.input-width-fee-active {
  border: 2px solid #179ad7;
}

.input-width-fee-active div {
  background-color: #179ad7 !important;
  color: white;
  border: 2px solid white;
}

.ant-input-number-handler-wrap {
  display: none;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.info-account {
  align-items: center;
  text-align: center;
}

.info-account p {
  color: #1890ff;
}

.header {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #00609c;
  height: 100px;
}

.header img {
  height: 80px;
  width: auto;
}

/* .header button {
  margin-left: auto;
} */

.main{
    background: url(/static/media/back-login.e79c7bdd.png) no-repeat center center;
    background-size: 80%;
    height: 100vh;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #ccc;
}

.row-header{
    height: 20%;
}

.logo {
    width: 250px;
    max-width: 100%;
    display: block;
    margin: auto;
}

.row-body{
    height: 80%;
}

.col-chica{
    display: flex;
    align-items: flex-end;
}

.col-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.col-form form{
    overflow: hidden;
}


.chicaavanzo{
    width: 500px;
    max-width: 100%;
    display: block;
    margin: auto;
}

.bottom-line {
    display: block;
    border-bottom: 1px solid #ccc;
    width: 50%;
    margin: auto;
    margin-top: 10px;
}

@media(max-width: 768px){

    .ant-col-offset-8{
        margin-left: 0px;
    }

    .ant-form-item-label{
        text-align: left;
    }

    body{
        background-attachment: fixed;
    }

    .main{
        border: none;
    }
}
.all-margin {
    padding-left: 20px;
    padding-right: 20px;
}

.btn-blue {
    background: #00609c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
    cursor:pointer;
    outline:none;
    transition:all ease .4s
}


.btn-blue:hover {
    color: #00609c;
    background: white;
    border:1px dashed #00609c;
}

label.labelUpload {
    display:block;
    text-align:Center;
    font-weight:bold;
    margin-top:10px;
}
.li-razon{
    width: 800px;
    display: block;
    margin: auto;
    margin-top: 10px;
}
.all-margin {
    padding-left: 20px;
    padding-right: 20px;
}

.btn-blue {
    background: #00609c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
    cursor:pointer;
    outline:none;
    transition:all ease .4s
}


.btn-blue:hover {
    color: #00609c;
    background: white;
    border:1px dashed #00609c;
}

label.labelUpload {
    display:block;
    text-align:Center;
    font-weight:bold;
    margin-top:10px;
}
.li-razon{
    width: 800px;
    display: block;
    margin: auto;
    margin-top: 10px;
}
.bottom-line {
    display: block;
    border-bottom: 1px solid #ccc;
    width: 50%;
    margin: auto;
    margin-top: 10px;
}
.password{
    padding: 40px 20px;
    
}

.formDatos{
    width: 80%;
    margin: auto;
}

h1{
    font-size: 2em;
    color: #4A4A4A;
    margin: 0;
    text-align: center;
    display: block;
    width: 100%;
}

h2{
    font-size: 1.5em;
    color: #4A4A4A;
    margin: 0;
    text-align: left;
    display: block;
    width: 100%;
}

h6{
    font-size: 1.2em;
    color: #4A4A4A;
    display: block;
    margin: 0;
    text-align: center;
    width: 100%;
}

.formPersonal{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formPersonal label {
        margin-top: 10px;
        margin-bottom: 5px

}

.checkbox {
    display: inline-block;
    width: 40px;
}


.files{
    padding: 40px 20px;
    
}

.formFiles {
    display: flex;
    justify-content: center;
    flex: 1 1;
    margin-top: 40px;
    width: 100%;
}

.file-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    width: 33%;
}

.file-item img {
    width: 100px;
    display: block;
    margin-bottom: 0px;
}

input[type=file] {
    display:none;
}

.file-upload {
    border: 1px solid #0086ff;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-top:5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.file-upload-text{
    color: #0086ff;
    font-weight: bold;
    font-size: 14px;
}

h2{
    font-size: 1.5em;
    color: #4A4A4A;
    margin: 0;
    text-align: left;
    display: block;
    width: 100%;
}

h1{
    font-size: 2em;
    color: #4A4A4A;
    margin: 0;
    text-align: center;
    display: block;
    width: 100%;
}

h5{
    font-size: 1.2em;
    color: #4A4A4A;
    display: block;
    margin: 0;
    text-align: left;
    width: 100%;
}

@media (max-width: 768px) {
    .formFiles{
        flex-direction: column;
    }
}
.conditions {
  padding: 40px 20px;
}

h1,
h2 {
  font-size: 2em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.selectedProduct {
  display: flex;
  background: #96cdff;
  border-radius: 25px;
  justify-content: center;
  margin: auto;
  width: 200px;
  padding: 5px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #003d5f;
  font-weight: bold;
}

.formCondicion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.formCondicion label {
  margin-top: 10px;
  margin-bottom: 10px;
}

.monto {
  font-size: 3em;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
}

.slider {
  width: 500px;
  max-width: 100%;
  margin: auto;
}

.ant-slider-rail {
  background: #96cdfc;
}

.ant-slider-handle {
  background: #0086ff;
}

/* Cuotas */
.cuotas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cuotas label {
  margin-bottom: 20px;
  margin-top: 40px;
}

.formCuotas {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.formCuotas .cuota {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  color: #4a4a4a;
  transition: all ease-in-out 0.3s;
}

.formCuotas .cuota:hover,
.formCuotas .cuota.active {
  background: #0086ff;
  color: white;
}

.ant-collapse-ghost {
  width: 500px;
  max-width: 100%;
  margin: auto;
  display: block;
}

.ant-collapse-header {
  text-align: center;
  color: #0086ff !important;
}

.ant-collapse-header span {
  color: #0086ff;
}

.boxCondicion {
  display: flex;
  background: #96cdfc;
  text-align: center;
  color: #003d73;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  width: 280px;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.item-condicion-rateName {
  font-size: 2em;
  font-weight: bold;
}

.bank-button {
  background-color: #f2e879;
  color: #00609c;
  font-weight: 510;
  border-radius: 40px !important;
  font-size: 20px;
  width: 300px;
  height: 60px;
}

.ant-btn:active {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
  background: #fff !important;
  text-decoration: none !important;
}

.bank-button-efecty {
  background-color: transparent;
  color: #00609c;
  font-weight: 510;
  border-radius: 40px !important;
  font-size: 20px;
  width: 300px;
  height: 60px;
}

.logo-efecty {
  background-color: #ffd600;
  width: auto;
  border-radius: 40px !important;
  height: -webkit-fill-available;
}

.infoQuota{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 15px 12%;
  color: #00609c;
}

@media (max-width: 767px) {
  .text-align-center-mobile {
    text-align: center;
  }

  .d-hidden-mobile {
    display: none;
  }

  .bank-select {
    display: block;
  }
}

@media (max-width: 450px) {
  .bank-select {
    display: block;
  }
}

@media (max-width: 300px) {
  .bank-select {
    display: block;
  }

  .bank-button-efecty {
    width: 200px;
  }

  .bank-button {
    width: 200px;
  }
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  color: #00609c !important;
  background-color: white !important;
  text-align: center !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  background-color: #00609c !important;
  color: white !important;
  text-align: center !important;
}
.soat {
    width: 60%;
    margin: auto;
}

.formSoat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formSoat label {
        margin-top: 10px;
        margin-bottom: 10px

}
.product {
  padding: 40px 20px;
}

.product h1 {
  font-size: 1.6em;
  color: #6a6a6a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

.product h2 {
  font-size: 1.5em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

.product h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.product-box {
  /* border: 1px solid #c4c4c4; */
  border: none;
  width: 100%;
  /* cursor: pointer; */
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.product-container {
  display: flex;
  /* justify-content: space-between; */ /* cuando hay soat y avanzo express */
  justify-content: center; /*  cuando solo hay 1 tarjeta */
  margin-top: 40px;
}

.contentProduct {
  width: 45%;
  display: grid;
}

.button-req {
  background-color: #f2e879;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 1px solid black;
}

.button-req-detail {
  background-color: #0d5d8d;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 1px solid black;
  color: white;
}

.text {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: block;
}

.product-box:hover {
  background: #f2f7fc;
}
.product-box:hover h2,
.product-box.active h2 {
  color: black;
}

.product-box.active {
  color: white;
  background: #179ad7;
}

.product-box h3 {
  font-weight: 600;
  font-size: 18px;
  color: #4a4a4a;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.product-box h4 {
  color: #4a4a4a;
  font-weight: 300;
  transition: all 0.3s ease-in-out;
}

.product-box img {
  height: 120px;
  transition: all 0.3s ease-in-out;
}

.product-box:hover h4,
.product-box.active h4 {
  color: white;
  background-color: white;
}

.product-box:hover h3 {
  color: black;
}
.product-box.active h3 {
  color: white;
}

.product-box:hover img {
  -webkit-filter: contrast(200%);
          filter: contrast(200%);
}
.product-box.active img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .product-box {
    width: 48%;
    margin-bottom: 20px;
  }

  .product-box img {
    width: 150px;
  }
}

@media (max-width: 450px) {
  .product-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .product-box img {
    width: 115%;
    height: 60px;
  }

  .product h2 {
    font-size: 1em;
  }
}

.contact{
    padding: 40px 20px;
    
}

.formDatos{
    width: 80%;
    margin: auto;
}

h1{
    font-size: 2em;
    color: #4A4A4A;
    margin: 0;
    text-align: center;
    display: block;
    width: 100%;
}

h2{
    font-size: 1.5em;
    color: #4A4A4A;
    margin: 0;
    text-align: left;
    display: block;
    width: 100%;
}

h6{
    font-size: 1.2em;
    color: #4A4A4A;
    display: block;
    margin: 0;
    text-align: center;
    width: 100%;
}

.formPersonal{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formPersonal label {
        margin-top: 10px;
        margin-bottom: 5px

}


.personal {
  padding: 40px 20px;
}

.formDatos {
  width: 80%;
  margin: auto;
}

h1 {
  font-size: 1.6em;
  color: #6a6a6a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h2 {
  font-size: 1.5em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.formPersonal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formPersonal label {
  margin-top: 10px;
  margin-bottom: 5px;
}

body {
  background: none;
}

.step-counter {
  width: 80%;
  margin: auto;
  display: block;
}

.step-counter-container {
  display: flex;
}

.step-counter-item {
  text-align: center;
}

.registro {
  width: 60%;
  display: block;
  margin: auto;
  margin-top: 5%;
  padding-bottom: 40px;
}

.step-bar {
  background: #96cdff;
  height: 4px;
  width: 100%;
  display: block;
  position: relative;
  margin-top: 5px;
}

.step-bar-progress {
  background: #0086ff;
  height: 4px;
  width: 0%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease;
}

.botones {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  margin-top: 40px;
}

.step-counter-item-point {
  width: 15px;
  height: 15px;
  background: #0086ff;
  border-radius: 100%;
  margin-left: 31%;
  position: relative;
  float: left;
  margin-top: -5px;
}

.back-green {
  background: #00e5d0;
}

.point-container {
  display: flex;
  width: 100%;
  justify-content: end;
}

.step-container-top {
  width: 100%;
  overflow: hidden;
  height: 40px;
}

.modal-registrado {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  cursor: pointer;
}

img.imagen-registrado {
  display: block;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button-req {
  background-color: #eff23a;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 2px solid #0086ff;
  font-weight: 600;
  color: #0086ff;
}

@media (max-width: 1024px) {
  .registro {
    width: 80%;
  }
}

@media (max-width: 997px) {
  .registro {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .botones button {
    margin-right: 10px;
    margin-left: 10px;
  }

  .step-counter {
    width: 288%;
  }

  .mtl-10 {
    margin-left: -10%;
  }

  .mtl-110 {
    margin-left: -110%;
  }

  .mtl-210 {
    margin-left: -210%;
  }

  .mtr-5 {
    margin-left: 10%;
  }
}

.password {
  padding: 40px 20px;
}

.formDatos {
  width: 80%;
  margin: auto;
}

h1 {
  font-size: 2em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h2 {
  font-size: 1.5em;
  color: #4a4a4a;
  margin: 0;
  text-align: left;
  display: block;
  width: 100%;
}

h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.formPersonal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formPersonal label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.checkbox {
  display: inline-block;
  width: 40px;
}

