.files{
    padding: 40px 20px;
    
}

.formFiles {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-top: 40px;
    width: 100%;
}

.file-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
    width: 33%;
}

.file-item img {
    width: 100px;
    display: block;
    margin-bottom: 0px;
}

input[type=file] {
    display:none;
}

.file-upload {
    border: 1px solid #0086ff;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-top:5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.file-upload-text{
    color: #0086ff;
    font-weight: bold;
    font-size: 14px;
}

h2{
    font-size: 1.5em;
    color: #4A4A4A;
    margin: 0;
    text-align: left;
    display: block;
    width: 100%;
}

h1{
    font-size: 2em;
    color: #4A4A4A;
    margin: 0;
    text-align: center;
    display: block;
    width: 100%;
}

h5{
    font-size: 1.2em;
    color: #4A4A4A;
    display: block;
    margin: 0;
    text-align: left;
    width: 100%;
}

@media (max-width: 768px) {
    .formFiles{
        flex-direction: column;
    }
}