.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.input-width,
.input-width-fee,
.input-width-fee-active {
  width: 350px;
  max-width: 100%;
}

.input-width-fee-active {
  border: 2px solid #179ad7;
}

.input-width-fee-active div {
  background-color: #179ad7 !important;
  color: white;
  border: 2px solid white;
}

.ant-input-number-handler-wrap {
  display: none;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.info-account {
  align-items: center;
  text-align: center;
}

.info-account p {
  color: #1890ff;
}
