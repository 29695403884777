.all-margin {
    padding-left: 20px;
    padding-right: 20px;
}

.btn-blue {
    background: #00609c;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 2px;
    cursor:pointer;
    outline:none;
    transition:all ease .4s
}


.btn-blue:hover {
    color: #00609c;
    background: white;
    border:1px dashed #00609c;
}

label.labelUpload {
    display:block;
    text-align:Center;
    font-weight:bold;
    margin-top:10px;
}
.li-razon{
    width: 800px;
    display: block;
    margin: auto;
    margin-top: 10px;
}