body {
  background: none;
}

.step-counter {
  width: 80%;
  margin: auto;
  display: block;
}

.step-counter-container {
  display: flex;
}

.step-counter-item {
  text-align: center;
}

.registro {
  width: 60%;
  display: block;
  margin: auto;
  margin-top: 5%;
  padding-bottom: 40px;
}

.step-bar {
  background: #96cdff;
  height: 4px;
  width: 100%;
  display: block;
  position: relative;
  margin-top: 5px;
}

.step-bar-progress {
  background: #0086ff;
  height: 4px;
  width: 0%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.5s ease;
}

.botones {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  margin-top: 40px;
}

.step-counter-item-point {
  width: 15px;
  height: 15px;
  background: #0086ff;
  border-radius: 100%;
  margin-left: 31%;
  position: relative;
  float: left;
  margin-top: -5px;
}

.back-green {
  background: #00e5d0;
}

.point-container {
  display: flex;
  width: 100%;
  justify-content: end;
}

.step-container-top {
  width: 100%;
  overflow: hidden;
  height: 40px;
}

.modal-registrado {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  cursor: pointer;
}

img.imagen-registrado {
  display: block;
  max-width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button-req {
  background-color: #eff23a;
  width: auto;
  height: auto;
  border-radius: 20px;
  border: 2px solid #0086ff;
  font-weight: 600;
  color: #0086ff;
}

@media (max-width: 1024px) {
  .registro {
    width: 80%;
  }
}

@media (max-width: 997px) {
  .registro {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .botones button {
    margin-right: 10px;
    margin-left: 10px;
  }

  .step-counter {
    width: 288%;
  }

  .mtl-10 {
    margin-left: -10%;
  }

  .mtl-110 {
    margin-left: -110%;
  }

  .mtl-210 {
    margin-left: -210%;
  }

  .mtr-5 {
    margin-left: 10%;
  }
}
