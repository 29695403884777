.conditions {
  padding: 40px 20px;
}

h1,
h2 {
  font-size: 2em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.selectedProduct {
  display: flex;
  background: #96cdff;
  border-radius: 25px;
  justify-content: center;
  margin: auto;
  width: 200px;
  padding: 5px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #003d5f;
  font-weight: bold;
}

.formCondicion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.formCondicion label {
  margin-top: 10px;
  margin-bottom: 10px;
}

.monto {
  font-size: 3em;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
}

.slider {
  width: 500px;
  max-width: 100%;
  margin: auto;
}

.ant-slider-rail {
  background: #96cdfc;
}

.ant-slider-handle {
  background: #0086ff;
}

/* Cuotas */
.cuotas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cuotas label {
  margin-bottom: 20px;
  margin-top: 40px;
}

.formCuotas {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.formCuotas .cuota {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  color: #4a4a4a;
  transition: all ease-in-out 0.3s;
}

.formCuotas .cuota:hover,
.formCuotas .cuota.active {
  background: #0086ff;
  color: white;
}

.ant-collapse-ghost {
  width: 500px;
  max-width: 100%;
  margin: auto;
  display: block;
}

.ant-collapse-header {
  text-align: center;
  color: #0086ff !important;
}

.ant-collapse-header span {
  color: #0086ff;
}

.boxCondicion {
  display: flex;
  background: #96cdfc;
  text-align: center;
  color: #003d73;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  width: 280px;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.item-condicion-rateName {
  font-size: 2em;
  font-weight: bold;
}

.bank-button {
  background-color: #f2e879;
  color: #00609c;
  font-weight: 510;
  border-radius: 40px !important;
  font-size: 20px;
  width: 300px;
  height: 60px;
}

.ant-btn:active {
  color: #40a9ff !important;
  border-color: #40a9ff !important;
  background: #fff !important;
  text-decoration: none !important;
}

.bank-button-efecty {
  background-color: transparent;
  color: #00609c;
  font-weight: 510;
  border-radius: 40px !important;
  font-size: 20px;
  width: 300px;
  height: 60px;
}

.logo-efecty {
  background-color: #ffd600;
  width: auto;
  border-radius: 40px !important;
  height: -webkit-fill-available;
}

.infoQuota{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  margin: 15px 12%;
  color: #00609c;
}

@media (max-width: 767px) {
  .text-align-center-mobile {
    text-align: center;
  }

  .d-hidden-mobile {
    display: none;
  }

  .bank-select {
    display: block;
  }
}

@media (max-width: 450px) {
  .bank-select {
    display: block;
  }
}

@media (max-width: 300px) {
  .bank-select {
    display: block;
  }

  .bank-button-efecty {
    width: 200px;
  }

  .bank-button {
    width: 200px;
  }
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  color: #00609c !important;
  background-color: white !important;
  text-align: center !important;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  background-color: #00609c !important;
  color: white !important;
  text-align: center !important;
}