.password {
  padding: 40px 20px;
}

.formDatos {
  width: 80%;
  margin: auto;
}

h1 {
  font-size: 2em;
  color: #4a4a4a;
  margin: 0;
  text-align: center;
  display: block;
  width: 100%;
}

h2 {
  font-size: 1.5em;
  color: #4a4a4a;
  margin: 0;
  text-align: left;
  display: block;
  width: 100%;
}

h6 {
  font-size: 1.2em;
  color: #4a4a4a;
  display: block;
  margin: 0;
  text-align: center;
  width: 100%;
}

.formPersonal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formPersonal label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.checkbox {
  display: inline-block;
  width: 40px;
}
