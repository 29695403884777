@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700;900&display=swap');
@import '../node_modules/antd/dist/antd.css';

html, body {
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn-primary{
  background: #0086FF;
}

.fore-blue{
  color: #0086FF;
}

.fore-gray{
  color: #626262;
}

.text-center{
  text-align: center;
}