.main{
    background: url(../../../../src/assets/img/back-login.png) no-repeat center center;
    background-size: 80%;
    height: 100vh;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #ccc;
}

.row-header{
    height: 20%;
}

.logo {
    width: 250px;
    max-width: 100%;
    display: block;
    margin: auto;
}

.row-body{
    height: 80%;
}

.col-chica{
    display: flex;
    align-items: flex-end;
}

.col-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.col-form form{
    overflow: hidden;
}


.chicaavanzo{
    width: 500px;
    max-width: 100%;
    display: block;
    margin: auto;
}

.bottom-line {
    display: block;
    border-bottom: 1px solid #ccc;
    width: 50%;
    margin: auto;
    margin-top: 10px;
}

@media(max-width: 768px){

    .ant-col-offset-8{
        margin-left: 0px;
    }

    .ant-form-item-label{
        text-align: left;
    }

    body{
        background-attachment: fixed;
    }

    .main{
        border: none;
    }
}